<script>
import TheInnerToolbar from "../organisms/TheInnerToolbar.vue";
import BillingHistoryProfile from "@/components/molecules/BillingHistoryProfile.vue";
import AppDialog from "@/components/atoms/AppDialog.vue";
import BillingHistoryList from "@/components/organisms/BillingHistoryList.vue";
import moment from "moment";

export default {
  name: "BillingHistoryTemplate",
  components: {
    TheInnerToolbar,
    BillingHistoryProfile,
    BillingHistoryList,
    AppDialog,
  },
  data() {
    return {
      dialog: false,
      billingData: {},
    };
  },
  props: {
    member: Object,
    billingList: Array,
  },
  computed: {
    orderedBilling: function () {
      let list = this.billingList;
      return list
        ? list.sort(
            (a, b) =>
              moment(b?.reference).diff(a?.reference) ||
              moment(b?.date).diff(a?.date)
          )
        : [];
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    billingEdit(payload) {
      this.$emit("billingEdit", payload);
    },
    deleteConfirm() {
      this.dialog = false;
      this.$emit("billingDelete", this.billingData);
    },
    deleteCancel() {
      this.dialog = false;
    },
    billingDelete(payload) {
      this.dialog = true;
      this.billingData = payload;
    },
    addBilling() {
      this.$emit("addBilling");
    },
  },
};
</script>

<template>
  <section class="billing-history-container section-container">
    <the-inner-toolbar
      title="Histórico de Pagamentos"
      subtitle="Membro"
      icon="mdi-file-document-plus-outline"
      @click="addBilling"
      @cancel="cancel"
    />
    <billing-history-profile
      id="billing-history-profile"
      :name="member.name"
      :image="member.image"
    />
    <billing-history-list
      :billingList="orderedBilling"
      @billingEdit="billingEdit"
      @billingDelete="billingDelete"
    />
    <app-dialog
      :dialog="dialog"
      title="Confirmar Exclusão"
      text="Tem certeza que deseja excluir o Pagamento?"
      buttonCancel="Cancelar"
      buttonConfirm="Excluir"
      @clickConfirm="deleteConfirm"
      @clickCancel="deleteCancel"
    />
  </section>
</template>

<style lang="scss" scoped>
.billing-history-container {
  min-width: 100% !important;
  min-height: auto !important;
  justify-content: flex-start;
  @media screen and (min-width: 1200px) {
    padding: 5vw;
  }

  h3 {
    font-size: 16px;
    text-align: center;
    margin: 0 auto;
    padding: 0 0.6em 42px 0.6em;
  }
}

#billing-history-profile {
  width: 100% !important;
  margin: 0 auto;

  @media screen and (min-width: 1200px) {
    width: 30% !important;
  }
}

.report-reference {
  width: 100%;
  text-align: center;
}
</style>
