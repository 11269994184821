<script>
import format from "@/helpers/format";
import AppAvatar from "../atoms/AppAvatar.vue";

export default {
  name: "BillingHistoryProfile",
  components: {
    AppAvatar,
  },
  data() {
    return {};
  },
  props: {
    image: {
      type: String,
    },
    name: {
      type: String,
      default: "",
    },
  },
  computed: {
    formatedName: function () {
      return format.name(this.name);
    },
  },
};
</script>

<template>
  <span style="width: 100%">
    <div class="billing-history-profile-container">
      <app-avatar
        :image="image"
        customHeight="64px"
        customWidth="78px"
        customStyle="border-radius: 20px 0 0 20px; margin: 0;"
      />
      <div class="billing-history-profile-name">
        <p>{{ formatedName }}</p>
      </div>
    </div>
  </span>
</template>

<style lang="scss">
.billing-history-profile-container {
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  flex-basis: auto;
  justify-content: flex-start;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 34px rgba(33, 76, 158, 0.25);

  .billing-history-profile-name {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    font-weight: 500;
    margin-left: 8px;

    p {
      transform: translateY(-4px);
    }
  }
}
</style>
