<script>
import BillingHistoryCard from "@/components/molecules/BillingHistoryCard.vue";
import AppList from "@/components/atoms/AppList.vue";

export default {
  name: "BillingHistoryList",
  components: { AppList, BillingHistoryCard },
  data() {
    return {};
  },
  props: {
    billingList: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    billingEdit(payload) {
      this.$emit("billingEdit", payload);
    },
    billingDelete(payload) {
      this.$emit("billingDelete", payload);
    },
  },
};
</script>

<template>
  <section id="billing-history-container" v-if="billingList != undefined">
    <app-list
      id="billing-history"
      v-if="billingList.length > 0"
      customStyle="padding-bottom: calc(144px) !important; min-height: 70px !important;"
    >
      <billing-history-card
        v-for="billing in billingList"
        v-bind:key="billing.id"
        :billing="billing"
        @billingEdit="billingEdit"
        @billingDelete="billingDelete"
      />
    </app-list>
  </section>
</template>

<style lang="scss">
#billing-history-container {
  width: 100%;

  .v-list {
    background: transparent;
  }
}
#billing-history {
  gap: 4px 16px;
  flex-wrap: wrap;
  display: flex;

  .v-list-item {
    @media screen and (min-width: 768px) {
      flex: 1 1 400px;
      max-width: max(calc(50% - 16px), 400px);
    }
    @media screen and (min-width: 1200px) {
      flex: 1 1 calc((100% - 32px) / 3);
      max-width: calc((100% - 32px) / 3);
    }
  }
}
</style>
