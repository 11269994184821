<script>
import format from "@/helpers/format";
import paymentMethods from "@/constants/payment-methods";
import AppButton from "../atoms/AppButton.vue";
import AppIcon from "../atoms/AppIcon.vue";
import AppListItem from "../atoms/AppListItem.vue";

export default {
  name: "BillingHistoryCard",
  components: {
    AppButton,
    AppIcon,
    AppListItem,
  },
  data() {
    return {};
  },
  props: {
    billing: {
      type: Object,
    },
  },
  computed: {
    formatedDate: function () {
      return format.date(this.billing.date);
    },
    formatedReference: function () {
      return format.reference(this.billing.reference);
    },
    billingMethod() {
      let method = "--";
      if (this.billing.method.length > 0)
        paymentMethods.find((item) => {
          if (item.value === this.billing.method) {
            method = item.text;
          }
        });
      return method;
    },
    billingPrice() {
      let price = "--";
      if (this.billing.price != undefined && this.billing.price != "") {
        if (typeof this.billing.price === "number") {
          price = this.billing.price.toString();
        } else {
          price = this.billing.price;
        }
        let indexComma = price.indexOf(".");
        if (indexComma != -1) {
          price =
            price.substring(0, indexComma) +
            "," +
            price.substring(indexComma + 1);
        } else {
          price = price + ",00";
        }
        indexComma = price.indexOf(",");
        let length = price.length;
        if (length >= 7) {
          for (let i = indexComma - 1; i > 0; i--) {
            if ((length - i) % 3 === 0 && length - i > 0) {
              price = price.slice(0, i) + "." + price.slice(i);
            }
          }
        }
      }
      return price;
    },
    /* formatedDate: function () {
      return format.date(this.billing.date);
    }, */
  },
  methods: {
    clickEdit() {
      this.$emit("billingEdit", this.billing);
    },
    clickDelete() {
      this.$emit("billingDelete", this.billing);
    },
  },
};
</script>

<template>
  <app-list-item>
    <span class="billing-history-card-container">
      <span class="billing-history-card">
        <div class="billing-history-title">
          <h4>
            {{ formatedReference }}
          </h4>
        </div>
        <div class="divider"></div>
        <div class="billing-history-data">
          <p>
            <small> Mensalidade </small>
          </p>
          <p style="text-align: right">
            <small> {{ billingMethod }} </small>
          </p>
        </div>
        <div class="billing-history-data">
          <p>
            <small
              ><app-icon color="#797979" size="16" icon="mdi-calendar-today" />
              {{ formatedDate }}
            </small>
          </p>
          <p>
            <small>
              <app-icon color="#797979" size="16" icon="mdi-currency-usd" />
              {{ billingPrice }}
            </small>
          </p>
        </div>
      </span>
      <span class="billing-history-card-buttons">
        <app-button
          type="icon"
          @click="clickEdit"
          size="48px"
          borderRadius="5px 20px 5px 5px"
          color="#FFFFFF"
          icon="mdi-pencil-outline"
        />
        <app-button
          type="icon"
          @click="clickDelete"
          size="48px"
          borderRadius="5px 5px 20px 5px"
          color="#FFFFFF"
          iconColor="red"
          icon="mdi-trash-can-outline"
        />
      </span>
    </span>
  </app-list-item>
</template>

<style lang="scss">
.v-list--three-line .v-list-item {
  min-height: 70px !important;
}
.billing-history-card-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  color: #797979;
  font-weight: 500;
  gap: 5px;
}
.divider {
  height: 1px;
  margin: 2px auto;
  background-color: #797979;
}
.billing-history-card {
  border-radius: 20px 5px 5px 20px;
  padding: 8px 16px;
  box-sizing: border-box;
  background-color: white;
  width: calc(100% - 69px);
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 34px rgba(33, 76, 158, 0.25);

  padding: 6px 18px 6px 18px;

  .billing-history-data {
    width: 100%;
    display: flex;
    padding-bottom: 8px;

    justify-content: space-between;
  }
  .billing-history-title {
    width: 100%;
    display: flex;
    padding-top: 8px;

    justify-content: space-between;
  }
}
.billing-history-card-buttons {
  width: 64px;
  align-items: flex-end;
  display: flex;
  gap: 4px;
  flex-direction: column;
  justify-content: space-between;
}
</style>
