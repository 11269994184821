<script>
import BillingHistoryTemplate from "@/components/templates/BillingHistoryTemplate.vue";

export default {
  name: "BillingHistoryPage",
  components: { BillingHistoryTemplate },
  data() {
    return {};
  },
  props: {
    id: String,
  },
  computed: {
    member: function () {
      return this.$store?.state?.user?.users.filter(
        (member) => member.id === this.id
      )[0];
    },
    billingList: function () {
      return this.$store?.state?.billing.billingList.filter(
        (billing) => billing.memberId === this.id
      );
    },
  },
  methods: {
    billingEdit(payload) {
      this.$router.push(payload.id + "/edit");
    },
    billingDelete(payload) {
      // run confirmation modal
      this.$store.dispatch("billing/delete", payload, { root: true });
    },
    cancel() {
      this.$router.go(-1);
    },
    goToBillingCreate(id) {
      this.$router.push("/member/" + id + "/billing/create");
    },
  },
};
</script>

<template>
  <billing-history-template
    :member="member"
    :billingList="billingList"
    @addBilling="goToBillingCreate(id)"
    @billingEdit="billingEdit"
    @billingDelete="billingDelete"
    @cancel="cancel"
  />
</template>

<style module></style>
